import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Discount/GetAll`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetAllDiscountForVendor extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Discount/GetAllVendorDiscount`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}


class DeleteDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Discount/Delete`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}


class UpdateDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Discount/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Discount/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class GetVendorPrices extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Price/GetVendorPrices');
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}
export {
    GetAllDiscount,
    DeleteDiscount,
    UpdateDiscount,
    CreateDiscount,
    GetVendorPrices,
    GetAllDiscountForVendor
};
