<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="Discounts !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered

          ok-title="بله"
          @ok="DeleteDiscount"
      >
        <span>آیا از حذف کردن این تخفیف اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          centered
          ok-only
          ok-title="بروزرسانی تخفیف"
          @ok.preventDefault()="UpdateDiscount"
      >
        <b-row v-if="SelectedDiscount">
          <b-col md="6">
            <label>مقدار تخفیف</label>
            <input v-model="SelectedDiscount.value" class="form-control" type="number">
          </b-col>
          <b-col md="6">
            <label>نوع تخفیف</label>
            <select v-model="SelectedDiscount.type" class="form-control">
              <option :value="1">درصدی</option>
              <option :value="2">عددی</option>
            </select>
          </b-col>
          <b-col class="my-2" md="12">
            <b-form-checkbox
                v-model="SelectedDiscount.refraction"
                checked="true"
                inline
                name="check-button"
                switch
            >
              تخفیف داغ
            </b-form-checkbox>
          </b-col>
          <b-col md="6">
            <label>تاریخ انقضا</label>
            <date-picker v-model="SelectedDiscount.expireDate"></date-picker>
          </b-col>
          <b-col v-if="SelectedDiscount.refraction" md="6">
            <label>تاریخ شروع</label>
            <date-picker v-model="SelectedDiscount.startDate"></date-picker>
          </b-col>


        </b-row>


      </b-modal>

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <app-collapse>

            <app-collapse-item title="">
              <template v-slot:header>
                <b-button variant="primary" @click="GetVendorPrices">
                  افزودن تخفیف جدید
                </b-button>
              </template>
              <b-row>
                <b-col md="6">
                  <label>مقدار تخفیف</label>
                  <input v-model="discount.value" class="form-control" type="number">
                </b-col>
                <b-col md="6">
                  <label>نوع تخفیف</label>
                  <select v-model="discount.type" class="form-control">
                    <option :value="1">درصدی</option>
                    <option :value="2">عددی</option>
                  </select>
                </b-col>
                <b-col class="my-2" md="12">
                  <b-form-checkbox
                      v-model="discount.refraction"
                      checked="true"
                      inline
                      name="check-button"
                      switch
                  >
                    تخفیف داغ
                  </b-form-checkbox>
                </b-col>
                <b-col v-if="discount.refraction" md="6">
                  <label>تاریخ شروع</label>
                  <date-picker v-model="discount.startDate"></date-picker>
                </b-col>
                <b-col md="6">
                  <label>تاریخ انقضا</label>
                  <date-picker v-model="discount.expireDate"></date-picker>
                </b-col>


              </b-row>
              <b-row>
                <b-col class="my-2" lg="10" md="12">
                  <debouncer :placeHolder="`جستجو`" @setValue="getsearchValue"></debouncer>

                </b-col>
                <b-col class="my-2" lg="2" md="12">
                  <v-select
                      v-model="SelectedCategoryId"
                      :options="Categories"
                      :reduce="name => name.productCategoryId"
                      label="name"
                  />
                </b-col>
                <b-col
                    class="d-flex align-items-center justify-content-start my-1 "
                    cols="12"
                    md="3"
                >
                  <b-form-checkbox
                      id="allPriceIds"
                      v-model="allPriceIds"
                  />
                  <label class="m-0" for="allPriceIds">انتخاب همه</label>
                </b-col>

              </b-row>
              <b-table
                  ref="refUserListTable"
                  :fields="PricesColumn"
                  :items="Prices"
                  bordered
                  :sort-by.sync="sortBy"
                  class="position-relative"
                  empty-text="موردی موجود نیست!"
                  primary-key="id"
                  responsive
                  show-empty
                  striped
              >

                <!-- Column: delete -->
                <template #cell(productInfo)="data">

                  <small>{{ data.item.productInfo.title }}</small>

                </template>
                <template #cell(optionValues)="data">

                   <span v-for="el in data.item.optionValues">
                  <small v-if="!el.value.includes('#')" class="text-secondary">
                      {{ el.value }}
                  </small>
                  <div v-else :style="`background-color:${el.value}`" class="p-2 rounded-circle"></div>
                </span>

                </template>
                <template #cell(priceValue)="data">

                  <small>{{ Intl.NumberFormat('fa-IR').format(data.item.priceValue) }} تومان</small>

                </template>

                <template #cell(Select)="data">
                  <b-form-checkbox
                      v-model="discount.priceIds"
                      :value="data.item.priceId"
                  >

                  </b-form-checkbox>
                </template>

              </b-table>
              <b-row>
                <!-- Pagination -->
                <b-col
                    class="d-flex align-items-center justify-content-end "
                    cols="12"
                    sm="12"
                >

                  <b-pagination
                      v-model="currentPagePrice"
                      :per-page="perPagePrice"
                      :total-rows="totalCountPrice"
                      class="mb-0 mt-1 mt-sm-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon"
                                    size="18"
                                    style="transform: rotate(180deg)"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
              <b-col class="my-2" cols="12">
                <b-button variant="success" @click="CreateDiscount">
                  ثبت
                </b-button>
              </b-col>
            </app-collapse-item>

          </app-collapse>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="Discounts"
            :sort-by.sync="discountsSortBy"
            bordered
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <!-- Column: delete -->
          <template #cell(value)="data">

            <div class="d-flex  flex-column justify-content-center">
              <small>{{ Intl.NumberFormat('fa-IR').format(data.item.value) }} </small>
              <div>

                <b-badge
                    v-if="data.item.type===1"
                    class="mr-75"
                    pill
                    variant="primary"
                >
                  درصدی
                </b-badge>
                <b-badge
                    v-if="data.item.type===2"
                    class="mr-75"
                    pill
                    variant="primary"
                >
                  عددی
                </b-badge>

              </div>
            </div>

          </template>
          <template #cell(expireDate)="data">

            <small>{{ new Date(data.item.expireDate).toLocaleDateString('fa-IR') }}</small>

          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedDiscount(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedDiscount(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
            </div>
          </template>

          <template #cell(refraction)="data">

            <span v-if="data.item.refraction">
              بله
            </span>
            <span v-else>
              خیر
            </span>
          </template>
          <template #cell(priceInfo)="data">

            <span>{{ data.item.priceInfo.productInfo.title }}</span>

          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  CreateDiscount,
  GetVendorPrices,
  DeleteDiscount,
  UpdateDiscount,
  GetAllDiscountForVendor
} from "@/libs/Api/Discount";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import helper from "@/libs/Helper";
import {GetAllProductCategoryForAll} from "@/libs/Api/productCategory";
import {getUserData} from "@/auth/utils";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: 'لیست تخفیف ها  - پنل ادمین انزا مد',
  name: 'Coupons',
  data() {
    return {
      Discounts: null,
      sortBy: '',
      Prices: [],
      discountsSortBy:'',
      totalCount: null,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'discountId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'expireDate',
          label: 'تاریخ انقضا',
          sortable: true
        },
        {
          key: 'value',
          label: 'میزان و نوع تخفیف',
          sortable: true
        },
        {
          key: 'refraction',
          label: 'تخفیف داغ',
          sortable: true
        },
        {
          key: 'priceInfo',
          label: 'محصول',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

        // { key: 'parentId'},
      ],
      PricesColumn: [
        {
          key: 'priceId',
          label: 'شناسه قیمت',
          sortable: true
        },
        {
          key: 'productInfo',
          label: 'محصول',
          sortable: true
        },
        {
          key: 'optionValues',
          label: 'ویژگی',
          sortable: true
        },
        {
          key: 'priceValue',
          label: 'قیمت',
          sortable: true
        },
        {
          key: 'Select',
          label: 'انتخاب'
        },

        // { key: 'parentId'},
      ],
      discount: {
        expireDate: "",
        type: null,
        value: null,
        refraction: false,
        priceIds: [],
        startDate: ''
      },
      allPriceIds: false,
      currentPage: 1,
      perPage: 10,
      SelectedDiscount: null,
      Categories: [],
      SelectedCategoryId: '',
      SearchPrices: '',
      currentPagePrice: 1,
      perPagePrice: 10,
      totalCountPrice: null,
      overlay: false

    }
  },
  async created() {
    await this.GetAllDiscount()
    await this.GetAllProductCategory();
  },

  components: {
    Debouncer,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {
    allPriceIds: function (nv, ov) {
      let _this = this;
      if (nv === true) {
        _this.Prices.forEach(el => {
          _this.discount.priceIds.push(el.priceId);
        })
      } else {
        _this.discount.priceIds = [];
      }
    },

    SelectedCategoryId: function () {
      this.GetVendorPrices();
    },
    currentPage: function () {
      this.GetAllDiscount()
    }
  },
  methods: {
    getsearchValue(val) {
      this.SearchPrices = val
      this.GetVendorPrices();

    },
    async GetAllProductCategory() {
      let _this = this
      _this.overlay = true

      let getAllProductCategory = new GetAllProductCategoryForAll(_this)

      await getAllProductCategory.fetch(function (content) {
        _this.Categories = content
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
    async GetVendorPrices() {
      let _this = this
      _this.overlay = true

      let getVendorPrice = new GetVendorPrices(_this)
      let data = {
        searchCommand: _this.SearchPrices,
        count: _this.perPagePrice,
        pageNumber: _this.currentPagePrice,
        productCategoryId: _this.SelectedCategoryId === null ? '' : _this.SelectedCategoryId
      }
      getVendorPrice.setParams(data)
      await getVendorPrice.fetch((content) => {
        _this.Prices = content.prices
        _this.totalCountPrice = content.pricesCount
      }, (err) => {
        console.log(err)
      })
      _this.overlay = false

    },
    async UpdateDiscount() {
      let _this = this
      _this.overlay = true

      let updateDiscount = new UpdateDiscount(_this)

      updateDiscount.setData(_this.SelectedDiscount)
      await updateDiscount.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تخفیف بروزرسانی شد`,
          },
        })
        _this.SelectedDiscount = null

        _this.GetAllDiscount();
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
    async CreateDiscount() {
      let _this = this
      _this.overlay = true

      if (_this.discount.type && _this.discount.value && _this.discount.expireDate !== '') {

        let createDiscount = new CreateDiscount(_this)

        createDiscount.setData(_this.discount)
        await createDiscount.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `تخفیف ساخته  شد`,
            },
          })
          _this.GetAllDiscount();
          _this.discount = {
            expireDate: "",
            type: null,
            value: null,
            refraction: false,
            priceIds: [],
            startDate: ''
          }
        }, function (error) {
          console.log(error)
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا همه فیلد ها را پر کنید`,
          },
        })
      }
      _this.overlay = false


    },
    async DeleteDiscount() {
      let _this = this
      _this.overlay = true

      let deleteDiscount = new DeleteDiscount(_this)
      deleteDiscount.setParams({id: this.SelectedDiscount.discountId})
      await deleteDiscount.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تخفیف  حذف شد.`,
          },
        })
        _this.GetAllDiscount();
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
    SetSelectedDiscount(item) {
      this.SelectedDiscount = JSON.parse(JSON.stringify(item))
    },
    async GetAllDiscount() {
      let _this = this
      _this.overlay = true
      let getAllDiscountForVendor = new GetAllDiscountForVendor(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: 10,
        vendorUserId: getUserData().userId
      }
      getAllDiscountForVendor.setParams(data)
      await getAllDiscountForVendor.fetch(function (content) {
        _this.Discounts = content.data.discounts
        _this.totalCount = content.data.counts
      }, function (error) {
        console.log(error)
      })
      _this.overlay = false

    },
  },
}
</script>

<style scoped>

</style>
